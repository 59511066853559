/*
 * @Author: ldd 1669455227@qq.com
 * @Date: 2024-03-08 11:08:42
 * @LastEditors: ldd 1669455227@qq.com
 * @LastEditTime: 2024-05-08 18:53:38
 * @FilePath: \vue-yjzyglxt-bs\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 3,
  newestOnTop: true,
  position: 'top-right',
  timeout: 20000,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.7,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
  type: 'error'
};

Vue.use(Toast, options);
//vue全局注入echarts
Vue.prototype.$echarts = echarts;


Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
