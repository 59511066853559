/*
 * @Author: ldd 1669455227@qq.com
 * @Date: 2024-03-08 11:08:42
 * @LastEditors: ldd 1669455227@qq.com
 * @LastEditTime: 2024-03-14 10:22:14
 * @FilePath: \vue-yjzyglxt-bs\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path:'/',
    redirect:'/login'
  },
  {
    path: '/home',
    name: 'home',
    component: ()=>import('../views/HomeView.vue'),
    children:[
      {
        path:'/employee',
        name:'employee',
        component:()=>import('@/views/employee/EmployeeView.vue')
      },
      {
        path:'/employee/add',
        name:'insert',
        component:()=>import('@/views/employee/AddEmployee.vue')
      },
      
      {
        path:'/category',
        name:"category",
        component:()=>import ('@/views/category/CategoryView.vue')
      },
      {
        path:'/category/add',
        name:'categoryInsert',
        component:()=>import('@/views/category/AddCategory.vue')
      },
      {
        path:'/equipment',
        name:'equipment',
        component:()=>import('@/views/equipment/EquipmentView.vue')
      },
      {
        path:'/equipment/add',
        name:'equipmentInsert',
        component:()=>import('@/views/equipment/AddEquipment.vue')
      },
      {
        path:'/companyEquipment',
        name:'companyEquipment',
        component:()=>import('@/views/company_equipment/CompanyEquipment.vue')
      },
      {
        path:'/orders',
        name:'orders',
        component:()=>import('@/views/orders/OrdersView.vue')
      }
        
    ]
  },

  {
    path:'/login',
    name:"login",
    component:()=>import('@/views/LoginView.vue')
  },
  
 
  {
    path:'/404',
    name:'404',
    component:()=>import('../views/ErrorFound.vue') 
  },
  {
    path:'/*',
    redirect:'404'
  }
]

const router = new VueRouter({
  routes,
  
})

router.beforeEach((to, from, next) => {
  console.log('正在进行路由跳转，现在要跳向的路由是：',to.fullPath);

  //如果目标url是login，直接放行
  if(to.fullPath==='/login'){
    console.log('login中当前token是',store.state.employee);
    next()
    return
  }
  // 每次路由跳转之前，先检测有没有token，如果有则可以跳转，如果没有则不能跳转
  if(store.state.employee.token === '' || store.state.employee.token===null || store.state.employee.token===undefined){
    //不能跳转，跳回登录页面
    console.log('不能跳转，跳回登录页面');
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    router.push('/login',()=>{})
    return
  }
    //说明有token可以跳转
    next()


})

export default router
