/*
 * @Author: ldd 1669455227@qq.com
 * @Date: 2024-03-08 11:08:42
 * @LastEditors: ldd 1669455227@qq.com
 * @LastEditTime: 2024-03-12 11:00:39
 * @FilePath: \vue-yjzyglxt-bs\src\store\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    employee:{
      id:"",
      username:'',
      token:''
    }
  },
  getters: {
  },
  mutations: {
    setEmployee(state:any,newVal){
      state.employee = newVal
    }
    
  },
  actions: {
  },
  modules: {
  }
})
